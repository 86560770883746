//import React from "react";
//import ReactDOM from "react-dom";
import i18n from "i18next";
import en from "./en/ns.json";
import de from "./de/ns.json";
import es from "./es/ns.json";
import nl from "./nl/ns.json";
import hr from "./hr/ns.json";
import se from "./se/ns.json";

import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  nl: {
    translation: nl,
  },
  hr: {
    translation: hr,    
  },
  se: {
    translation: se,    
  }  
} as const;

i18n.use(initReactI18next).init({
  lng: process.env.REACT_APP_LANGUAGE || 'en',
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
