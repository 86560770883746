import { RouteConfig } from './types';

export const seRoutes: RouteConfig = {
  home: '/home',
  loginWithType: '/logga-in/:userType',
  login: '/logga-in',
  forgotPassword: '/glömt-ditt-lösenord',
  changePassword: '/andra-lösenord',
  register: '/registrera',
  patientRegister: '/patient/registrera',
  patientThankYou: '/patient/tack',
  hcpRegister: '/sjukvårdspersonal/registrera',
  hcpThankYou: '/sjukvårdspersonal/tack',
  profile: '/ditt-konto',
  yourCondition: '/kom-igång/ditt-tillstånd',
  
  coachingTool: '/valbefinnande-och-support/ditt-valbefinnande/coaching-verktyg',
  hcpMakeRequestRepCall: '/sjukvårdspersonal/gör-en-beställning/bli-uppringd-av-produktspecialist',
  hcpRequestRepCall: '/sjukvårdspersonal/bli-uppringd-av-produktspecialist',
  hcpOrderKitIdacio: '/sjukvårdspersonal/gör-en-beställning/beställa-ett-kit-idacio',
  hcpOrderKitTyenne: '/sjukvårdspersonal/gör-en-beställning/beställa-ett-kit-tyenne',
  hcpOrderKitOtulfi: '/sjukvårdspersonal/gör-en-beställning/beställa-ett-kit-otulfi',
  dynamic: '',
  hcpAccess: '/sjukvårdspersonal',
  myTreatment: '/min-behandling',
  
  idacioUnderstanding: '/min-idacio/förstå-idacio',
  idacioHowToInject: '/min-idacio/injicera-idacio',
  idacioCaring: '/min-idacio/ta-hand-om-ditt-läkemedel-idacio',
  idacioHelp: '/min-idacio/hjälp-och-stöd-idacio',
  idacioGoalsSettings: '/min-idacio/mina-framsteg/mål/inställningar',
  idacioGoals: '/min-idacio/mina-framsteg/mål',
  idacioGoalsSettingsGoal: '/min-idacio/mina-framsteg/mål/inställningar/mål',
  idacioLogMedicationSettings: '/min-idacio/mina-framsteg/behandlingsdagbok/inställningar',
  idacioLogMedicationSettingsMedication: '/min-idacio/mina-framsteg/behandlingsdagbok/inställningar/behandling',
  idacioReminders: '/min-idacio/mina-framsteg/påminnelser',
  idacioProgress: '/min-idacio/mina-framsteg',
  idacioCoolbagThankYou: '/min-idacio/coolbag/tack',
  idacioCoolbag: '/min-idacio/beställ-en-kylväska-idacio',
  idacio: '/min-idacio',
  
  tyenneUnderstanding: '/min-tyenne/förstå-tyenne',
  tyenneHowToInject: '/min-tyenne/injicera-tyenne',
  tyenneCaring: '/min-tyenne/ta-hand-om-ditt-läkemedel-tyenne',
  tyenneHelp: '/min-tyenne/hjälp-och-stöd-tyenne',
  tyenneGoals: '/min-tyenne/mina-framsteg/mål',
  tyenneGoalsSettings: '/min-tyenne/mina-framsteg/mål/inställningar',
  tyenneGoalsSettingsGoal: '/min-tyenne/mina-framsteg/mål/inställningar/mål',
  tyenneLogMedication: '/min-tyenne/mina-framsteg/behandlingsdagbok/inställningar',
  tyenneLogMedicationSettingsMedication: '/min-tyenne/mina-framsteg/behandlingsdagbok/inställningar/behandling',
  tyenneReminders: '/min-tyenne/mina-framsteg/påminnelser',
  tyenneProgress: '/min-tyenne/mina-framsteg',
  tyenneCoolbagThankYou: '/min-tyenne/request-a-coolbag/tack',
  tyenneCoolbag: '/min-tyenne/beställ-en-kylväska-tyenne',
  tyenne: '/min-tyenne',
  
  otulfiUnderstanding: '/min-otulfi/förstå-otulfi',
  otulfiHowToInject: '/min-otulfi/injicera-otulfi',
  otulfiCaring: '/min-otulfi/ta-hand-om-ditt-läkemedel-otulfi',
  otulfiHelp: '/min-otulfi/hjälp-och-stöd-otulfi',
  otulfiGoalsSettings: '/min-otulfi/mina-framsteg/mål/inställningar',
  otulfiGoals: '/min-otulfi/mina-framsteg/mål',
  otulfiGoalsSettingsGoal: '/min-otulfi/mina-framsteg/mål/inställningar/mål',
  otulfiLogMedicationSettings: '/min-otulfi/mina-framsteg/lbehandlingsdagbok/inställningar',
  otulfiLogMedicationSettingsMedication: '/min-otulfi/mina-framsteg/behandlingsdagbok/inställningar/behandling',
  otulfiReminders: '/min-otulfi/mina-framsteg/påminnelser',
  otulfiProgress: '/min-otulfi/mina-framsteg',
  otulfiCoolbagThankYou: '/min-otulfi/coolbag/tack',
  otulfiCoolbag: '/min-otulfi/beställ-en-kylväska-otulfi',
  otulfi: '/min-otulfi',  
};