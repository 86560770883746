// src/routes/index.ts
import { Routes, LanguageCode } from './types';
import { baseRoutes } from './base';
import { enRoutes } from './en';
import { esRoutes } from './es';
import { deRoutes } from './de';
import { nlRoutes } from './nl';
import { hrRoutes } from './hr';
import { seRoutes } from './se';

export const routes: Routes = {
  en: { ...baseRoutes, ...enRoutes },
  es: { ...baseRoutes, ...esRoutes },
  de: { ...baseRoutes, ...deRoutes },
  nl: { ...baseRoutes, ...nlRoutes },
  hr: { ...baseRoutes, ...hrRoutes },
  se: { ...baseRoutes, ...seRoutes },    
};

// Function to get the current language from environment variable
export const getCurrentLanguage = (): LanguageCode => {
  return (process.env.REACT_APP_LANGUAGE || 'en') as LanguageCode;
};

// Function to get the correct route
export function getRoute(nameOrPath: string, params?: Record<string, string>): string {
  const currentLanguage = getCurrentLanguage();
  
  // Function to find a route name by its path
  const findRouteNameByPath = (path: string): string | undefined => {
    for (const [routeName, routePath] of Object.entries(routes[currentLanguage])) {
      if (routePath === path) {
        return routeName;
      }
    }
    // If not found in current language, check English routes
    if (currentLanguage !== 'en') {
      for (const [routeName, routePath] of Object.entries(routes['en'])) {
        if (routePath === path) {
          return routeName;
        }
      }
    }
    return undefined;
  };

  // Determine if input is a name or path
  const routeName = routes[currentLanguage][nameOrPath] ? nameOrPath : findRouteNameByPath(nameOrPath);
  
  if (!routeName) {
    console.warn(`No matching route found for: ${nameOrPath}`);
    return '/'; // Default to home route if no match found
  }

  let route = routes[currentLanguage][routeName] || routes['en'][routeName] || '/';
  
  if (params) {
    Object.keys(params).forEach(key => {
      route = route.replace(`:${key}`, params[key]);
    });
  }

  return route;
}